<script lang="ts" setup>
import type {
  ILeaseSpecialConditions,
  ILeaseSpecialCondition,
  ILeaseSummaryField,
} from '@register'
import { title as toTitle } from '#imports'
interface Props {
  data: ILeaseSpecialConditions
  title: string
}
const props = defineProps<Props>()

const hasSpecialConditions = computed(
  () =>
    Object.entries(props.data ?? {})?.length > 0 ||
    Object.entries(props.data?.added ?? {})?.length > 0 ||
    Object.entries(props.data?.deleted ?? {})?.length > 0 ||
    Object.entries(props.data?.itemChanged ?? {})?.length > 0,
)

const parseSpecialCondition = (
  specialCondition: ILeaseSpecialCondition,
): ILeaseSummaryField => {
  return {
    id: specialCondition.id,
    name: specialCondition.leaseField?.name,
    description: specialCondition.leaseField?.description,
    value: {
      value: specialCondition.value,
    },
  } as ILeaseSummaryField
}
</script>

<template>
  <div v-if="hasSpecialConditions" class="w-full divide-y divide-gray-700/50">
    <div class="mt-4 w-full">
      <h2 class="text-lg">{{ title }}</h2>
    </div>

    <div
      v-for="(conditions, kind) in data"
      :key="kind"
      class="odd:bg-[#3a3a3a] even:bg-[#333333]"
    >
      <span class="text-sm font-semibold">
        {{ toTitle(kind) }}
      </span>
      <RegisterSummaryField
        v-for="(condition, index) in conditions"
        :key="condition.id"
        :field="parseSpecialCondition(condition)"
        :index="index"
        class="mt-2"
      />
    </div>
  </div>
</template>
